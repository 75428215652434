import React, { useState } from "react";
const Popup = ({ showPopup, setShowPopUp }) => {
  const [email, setEmail] = useState("");

  function handleSubmit(e) {
    const url = "https://manage.kmail-lists.com/ajax/subscriptions/subscribe";
    // const _url = `https://a.klaviyo.com/api/v2/list/SZcDnJ/subscribe?api_key=${process.env.GATSBY_KLAVIYO_API_KEY}`;

    var details = {
      g: "SZcDnJ",
      email: email,
      $source: "mixbydanielle Site",
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    e.preventDefault();
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache",
      },
      body: formBody,
    })
      .then(() => {
        setShowPopUp(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div
      className={`fixed z-50 ${
        showPopup ? "block" : "hidden"
      } top-0 left-0 w-screen h-screen bg-black/30 flex items-center justify-center transform transition-opacity duration-150 ease-linear`}
    >
      <div className="relative max-w-[915px] w-full bg-white py-16 text-center flex flex-col items-center px-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute w-5 h-5 cursor-pointer top-6 right-6"
          viewBox="0 0 20 20"
          fill="currentColor"
          onClick={() => setShowPopUp(false)}
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        <h2 className="font-medium text-3xl leading-[53px] mt-1">
          Online Ordering Begins June 15th
        </h2>
        <p className="max-w-[525px] text-[13px] pb-5">
          Enter your email for a reminder!
        </p>

        <form className="max-w-[370px] w-full mt-6 mb-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full py-1 text-xs font-medium leading-5 text-center border border-black border-solid placeholder:text-black"
          />
          <button
            type="submit"
            className="py-2 text-xs font-medium leading-5 uppercase border-2 border-black border-solid px-9 mt-11"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Popup;
