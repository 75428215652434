import React, { Fragment } from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Liqour() {
  return (
    <Fragment>
      <div className="relative">
        <div className="">
          <StaticImage
            src="https://cdn.sanity.io/images/3tw47yx7/production/8e68d5dcd5e7c2d80e42526ba113ec241995eb72-5440x3072.png"
            alt="home-item1"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 flex justify-end items-center pr-[7.3%]">
          <StaticImage
            src="https://cdn.sanity.io/images/3tw47yx7/production/0d9ca45a4164b6092b51a32938a9aa22af4e304c-2168x1232.png"
            alt="home-items2"
            placeholder="blurred"
            className="h-[41%] w-[44%]"
          />
        </div>
      </div>
    </Fragment>
  );
}
