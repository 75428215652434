import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/home/Hero";
import List from "../components/home/List";
import Products from "../components/home/Products";
import Liqour from "../components/home/Liquor";
import Instagram from "../components/home/Instagram";
import NextEvent from "../components/home/NextEvent";
import NewsLetters from "../components/common/newsletter";
import Testimonials from "../components/home/Testimonials";
import Button from "../components/common/button";
import { navigate } from "gatsby";
import Popup from "../components/common/popup";
import { useWindowSize } from "../hooks/windowSize";
import Seo from "../components/common/SEO";

// markup
const IndexPage = ({ data: { sanityMixHome } }) => {
  const { width } = useWindowSize();
  const { heroSection, mixFunSection, favoriteSection, events, testimonials, seo } =
    sanityMixHome;
  const [showPopup, setShowPopup] = useState(false);
  return (
    <Layout>
      <Seo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
      />
      <Hero data={heroSection} />
      <List />
      <div className="relative min-h-[224px] md:mb-8 sm:mb-0">
        <GatsbyImage
          image={mixFunSection?.images?.backgroundImage?.asset?.gatsbyImageData}
          alt="home-items"
          className={
            width > 768
              ? `w-full min-h-full sm:mt-6`
              : `w-full h-[400px] min-h-full`
          }
        />
        <div
          className="absolute top-0 bottom-0 left-0 right-0"
          style={{
            backgroundColor: `rgba(${mixFunSection?.overlayColor?.rgb?.r},${mixFunSection?.overlayColor?.rgb?.g},${mixFunSection?.overlayColor?.rgb?.b},${mixFunSection?.overlayColor?.rgb?.a})`,
          }}
        />
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <p className="mt-[8px] md:mt-0 text-center flex flex-col justify-center sm:text-[20px] leading-[177.4%] font-primary text-black font-medium space-y-4 space-x-4">
            <span className=" text-[24px] font-bold">
              {mixFunSection?.captionFirst}
            </span>

            <span>
              <em>{mixFunSection?.captionSecond}</em>
            </span>

            <span>
              <em>{mixFunSection?.captionThird}</em>
            </span>

            <span>
              <em>{mixFunSection?.captionForth}</em>
            </span>
          </p>
        </div>
      </div>

      <div className="mix-container sm:-mt-14">
        <div>
          <GatsbyImage
            image={mixFunSection?.images?.overlayImage?.asset?.gatsbyImageData}
            alt="home-items"
          />
        </div>
        <div className="mt-[54px]">
          <Products
            title={favoriteSection?.title}
            description={favoriteSection?.description}
            productList={favoriteSection?.products}
          />
        </div>
      </div>
      <div className="mt-[85px]">
        <Liqour />
      </div>
      {/* <div className="mt-[77px]">
        <Process />
      </div> */}
      <div className="flex items-center justify-center my-8">
        <Button
          text="ABOUT US"
          type="dark"
          onClick={() => navigate("/about-us")}
        />
      </div>
      <div className="mix-container mt-[70px]">
        <NextEvent title={events.title} images={events.images} />
      </div>
      <div className="mt-[90px]">
        <Testimonials list={testimonials.length > 0 ? testimonials : []} />
      </div>
      <div className="mt-[93px] overflow-x-hidden">
        <Instagram />
      </div>
      <div className="mt-[122px]">
        <NewsLetters
          title="JOIN THE INNER CIRCLE"
          caption="Be the first to know about MIX By Danielle exclusives, special offers and new seasonal items"
          small={true}
        />
      </div>
      <Popup showPopup={showPopup} setShowPopUp={setShowPopup} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    sanityMixHome {
      heroSection {
        title
        _rawDescription
        backgroundImage {
          bgDesktop {
            asset {
              url
            }
          }
          bgMobile {
            asset {
              url
            }
          }
        }
        ctaBtn {
          title
          slug
        }
      }
      mixFunSection {
        captionFirst
        captionForth
        captionSecond
        captionThird
        images {
          backgroundImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          overlayImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
        overlayColor {
          rgb {
            r
            g
            b
            a
          }
        }
      }
      favoriteSection {
        title
        description
        products {
          title
          slug
          description
          thumbnail {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
      events {
        title
        images {
          asset {
            gatsbyImageData
          }
        }
      }
      testimonials {
        author
        authorDescription
        feedback
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
