/** @format */

import React from "react";
import Slider from "react-slick";

const LeftArrow = (props) => {
  return (
    <div {...props} className='absolute top-[31px] z-10'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='29'
        viewBox='0 0 17 29'
        fill='none'>
        <path d='M16 28L2 14.5L16 1' stroke='white' strokeWidth='2' />
      </svg>
    </div>
  );
};
const RightArrow = (props) => {
  return (
    <div {...props} className='absolute top-[31px] right-0'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='29'
        viewBox='0 0 17 29'
        fill='none'>
        <path d='M1 1L15 14.5L1 28' stroke='white' strokeWidth='2' />
      </svg>
    </div>
  );
};
export default function Testimonials({ list }) {
  const starColor = "#FFD700";

  const settings = {
    dots: false,
    infinite: true,
    autplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: (
    //   <div>
    //     <svg
    //       xmlns='http://www.w3.org/2000/svg'
    //       width='17'
    //       height='29'
    //       viewBox='0 0 17 29'
    //       fill='none'>
    //       <path d='M1 1L15 14.5L1 28' stroke='white' strokeWidth='2' />
    //     </svg>
    //   </div>
    // ),
  };
  return (
    <div className='bg-[#2C2C2C] pt-[70px] pb-[80px] px-4 text-white'>
      <div className='flex flex-col items-center mb-[5px] sm:mb-0'>
        <h1 className='text-[25px] leading-[44px] uppercase font-bold flex space-x-1.5 '>
          {[1, 2, 3, 4, 5].map((val) => (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='28'
              viewBox='0 0 30 28'
              fill='none'
              key={val}>
              <path
                d='M15 0L18.3677 10.3647H29.2658L20.4491 16.7705L23.8168 27.1353L15 20.7295L6.18322 27.1353L9.55093 16.7705L0.734152 10.3647H11.6323L15 0Z'
                fill={starColor}
              />
            </svg>
          ))}
        </h1>
        <span className='h-[2px] bg-white hidden sm:block mt-[20px] mb-[20px] w-[123px]' />
      </div>
      <div className='flex justify-center mt-4'>
        <div className='relative w-[93%] md:w-[50%] '>
          <Slider
            {...settings}
            prevArrow={<LeftArrow />}
            nextArrow={<RightArrow />}
            arrows={true}
            autoplay={true}
            autoplaySpeed={4000}>
            {list.map((item, index) => (
              <div className='px-8' key={index}>
                <div className='text-[50px] w-full text-white text-opacity-40'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='27'
                    height='21'
                    viewBox='0 0 27 21'
                    fill='none'>
                    <path
                      opacity='0.2'
                      d='M19.3871 21L12.9032 18.48L22.0968 0L27 2.00308L19.3871 21ZM6.48387 21L0 18.48L9.19355 0L14.0968 2.00308L6.48387 21Z'
                      fill='white'
                    />
                  </svg>
                </div>
                <p className='px-9 text-center text-[17px] font-medium'>
                  {item?.feedback}
                </p>
                <div className='text-[50px] w-full flex justify-end text-white text-opacity-40'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='27'
                    height='21'
                    viewBox='0 0 27 21'
                    fill='none'>
                    <path
                      opacity='0.2'
                      d='M7.6129 1.69487e-06L14.0968 2.52L4.90323 21L-1.75115e-07 18.9969L7.6129 1.69487e-06ZM20.5161 5.66838e-07L27 2.52L17.8065 21L12.9032 18.9969L20.5161 5.66838e-07Z'
                      fill='white'
                    />
                  </svg>
                </div>
                <div className='mt-2'>
                  <h1 className='text-[25px] font-medium text-center capitalize'>
                    {item?.author}
                  </h1>
                  <p className='text-[17px ] mt-2 text-center px-[30%]'>
                    {item?.authorDescription}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
