import React, { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Heading from "../common/heading";
import Slider from "react-slick";

const LeftArrow = (props) => {
  return (
    <div {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="29"
        viewBox="0 0 17 29"
        fill="none"
      >
        <path d="M16 28L2 14.5L16 1" stroke="black" strokeWidth="2" />
      </svg>
    </div>
  );
};
const RightArrow = (props) => {
  return (
    <div {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="29"
        viewBox="0 0 17 29"
        fill="none"
      >
        <path d="M1 1L15 14.5L1 28" stroke="black " strokeWidth="2" />
      </svg>
    </div>
  );
};

export default function NextEvent({ title, images }) {
  const settings = {
    dots: false,
    infinite: true,
    autplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="29"
          viewBox="0 0 17 29"
          fill="none"
        >
          <path d="M1 1L15 14.5L1 28" stroke="#2C2C2C" strokeWidth="2" />
        </svg>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Fragment>
      <div className="flex flex-col items-center px-4">
        <Heading
          title={title}
          // caption='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dis odio in amet integer mattis. Maecenas mattis neque, volutpat enim.'
        />
      </div>
      <div className="mt-3">
        <div className="w-[95%] mx-3">
          <div className="py-5 mx-5">
            <Slider
              {...settings}
              prevArrow={<LeftArrow />}
              nextArrow={<RightArrow />}
              arrows={true}
            >
              {images.map((item, index) => {
                return (
                  <div className="pl-2 cursor-pointer mb-7 md:mb-0" key={index}>
                    <div style={{ height: "288px" }}>
                      <GatsbyImage
                        image={item?.asset?.gatsbyImageData}
                        alt="event click"
                        style={{ height: "100%", margin: "4px" }}
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
