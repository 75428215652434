/** @format */

import React, { Fragment, useEffect, useState } from "react";
import Heading from "../common/heading";
import Slider from "react-slick";
import axios from "axios";

export default function Instagram() {
  const [feed, setFeed] = useState([]);
  const [error, setError] = useState(false);
  useEffect(() => {
    const getInstaFeed = async () => {
      try {
        const response = await axios.get(`https://ig-feed-etbud.ondigitalocean.app/ig-feed`);
        setFeed(response?.data);
        // setError(false);
      } catch (error) {
        setError(true);
      }
    };
    getInstaFeed();
  }, []);
  const sliderOptions = {
    className: "center",
    centerMode: true,
    infinite: true,
    autoplay: true,
    centerPadding: "84px",
    slidesToShow: 5,
    speed: 500,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          centerPadding: "50px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: "50px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "50px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerPadding: "50px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "50px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Fragment>
      <div className="flex flex-col items-center">
        <Heading title="FOLLOW OUR INSTAGRAM" />
      </div>
      <div className="mt-[93px] flex justify-center">
        <div className="w-full">
          {error ? (
            <div className="w-full text-center">
              <span className="text-center text-red-700">
                Something went wrong!
              </span>
            </div>
          ) : (
            <div className="instagram">
              <Slider {...sliderOptions}>
                {feed.map((item, index) => {
                  if (item?.media_type === "IMAGE")
                    return (
                      <div key={index}>
                        <a
                          href={item?.permalink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="px-3 h-[200px] md:h-[300px] w-full">
                            <img
                              src={item?.media_url}
                              alt=""
                              className="object-cover w-full h-full max-h-full"
                            />
                          </div>
                        </a>
                      </div>
                    );
                })}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
