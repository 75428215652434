import React from "react";
import { navigate } from "gatsby";
import PortableText from "react-portable-text";
import Button from "../common/button";
import { useWindowSize } from "../../hooks/windowSize";

export default function Hero({ data }) {
  const { width } = useWindowSize();

  return (
    <div className=" bg-gradient-to-r  from-[#f3e0c4] to-[#eedaba] h-[500px] sm:h-[670px]">
      {width !== 0 && (
        <div
          className="h-[500px] sm:h-[670px] bg-no-repeat  md:!bg-[length:1100px] bg-[length:150%] xs:bg-[length:120%]  bg-[bottom_right] md:bg-[center_right_-11rem] lg:bg-[center_right] bg-[#e5cfad]"
          style={{
            backgroundImage: `url(${
              width > 768
                ? data?.backgroundImage?.bgDesktop?.asset?.url
                : width > 450
                ? data?.backgroundImage?.bgMobile?.asset?.url
                : data?.backgroundImage?.bgMobile?.asset?.url
            })`,
          }}
        >
          <div className="mix-container pt-32 px-5 lg:px-0 lg:pt-[150px] xl:pt-[202px]">
            <div className=" max-w-[387px]">
              <p className="text-[23px] md:text-[30px] leading-[177.4%] font-medium font-primary">
                {data?.title}
              </p>
              <PortableText
                content={data?._rawDescription}
                className="text-[14px] md:text-[18px] leading-[177.4%] font-medium font-primary mt-[10px]"
              />
              <div className="mt-5">
                <Button
                  text={data?.ctaBtn?.title}
                  type="dark"
                  onClick={() => navigate(`/${data?.ctaBtn?.slug}`)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
