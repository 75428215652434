import React, { Fragment } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Heading from "../common/heading";

export default function Products({ title, description, productList }) {
  return (
    <Fragment>
      <div className="flex flex-col items-center">
        <Heading title={title} caption={description} />
      </div>
      <div className="mt-[96px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {productList.map((item, index) => {
          return (
            <Link
              className="flex flex-col items-center justify-center cursor-pointer"
              key={index}
              to={`/products/${item?.slug}`}
            >
              <div className="mb-8">
                <GatsbyImage
                  image={item?.thumbnail?.asset?.gatsbyImageData}
                  alt={item.title}
                  className="h-[340px] w-[140px]"
                />
              </div>
              <div className="flex flex-col items-center justify-center mb-[6px]">
                <p className="text-[15px] leading-[177.4%] text-black text-center mb-[6px]">
                  {item.title}
                </p>
                <span className="h-[1px] bg-black block w-[77px]"></span>
              </div>
              <p className="text-xs leading-[130%] text-center px-2 h-[45px]">
                {item?.description}
              </p>
            </Link>
          );
        })}
      </div>
      <div className="mt-[77px] flex justify-center">
        <Link
          className={`text-xs border-[1.5px] border-black text-black flex justify-center items-center font-primary font-medium tracking-[.21em] h-9 w-[160px]`}
          to="/products"
        >
          SEE MORE
        </Link>
      </div>
    </Fragment>
  );
}
